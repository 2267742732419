.Materials{
    font-size: 1.5em;
    text-align: left;
}
.Materials a{
    text-decoration: underline;
    font-size: 1em;
}
.Materials .Introduction{
    padding: 50px calc(5% + 50px) ;
    color :#3b3228;
    background-color: #498d6b63;
    font-style: italic;

    font-family: "Dancing Script", cursive;
}
.Materials .Content{
    padding: 50px calc(5% + 50px) ;
    vertical-align: center;
}
.Materials .Items div:first-child{
    margin-right: 25px;
}


@media (max-width: 1025px) {
    .Materials .Items{
        flex-direction: column;
    }
    .Materials .Invert{
        flex-direction: column-reverse;
    }
}