.Creations {

}

.CreationContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.CreationElement {
    display: flex;
    flex-direction: row;
    justify-items: center;
    /*align-items: center;*/
    justify-content: center;
    padding: calc(1em + 15px) 50px 10px 50px;
    flex-wrap: wrap;
}

.CreationElement:nth-child(2n) {
    flex-direction: row-reverse;
    background: #CBCBB854;
}

.CreationImage {
    min-width: 185px;
    max-width: 350px;
    /*height: 150px;*/
    object-fit: cover;
}

.CreationText {
    text-align: left;
    padding: 10px;
    width: 400px;
}

.CreationTitle {
    text-decoration: underline;
    font-weight: 900;
}
.TitleCreation{
    padding: 50px;
    font-size: 1.5em;
    text-decoration: underline;
    text-decoration-thickness: 1px;

    text-underline-offset: 0.2em;
}

.TitleCreation a{
    font-size: 1em;
    color: #92a99e;
}
.CarouselWrap{
    margin: 10px 0;
}