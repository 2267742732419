@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import url('https://fonts.cdnfonts.com/css/cracksmoon');
.Logo{
  padding: 25px;
}
.App {
  --fond: #f2f2ec;
  --fond_input: #cadad4;
  --fond_entete: #92a99e;
  --police: #413c3c;
  --police_entete: #304a43;
  --fond_boutton: #aa4f30;
  --police_boutton: #f5eae6;

  font-family: Halimah ;
  letter-spacing: 1.5px;
  background-color: var(--fond) ;
  font-weight: 600;
  color: var(--police);
  text-align: center;
  /*font-family: HelveticaNeue, Helvetica Neue, helvetica, Sans-Serif, serif;*/
}
.Title{
  font-size: 2em;
  background: var(--fond_entete);
  color: var(--police_entete);
  padding: 25px 0 ;
}
.SubTitle{
  font-size: 24px;
  background: var(--fond);
  color: var(--fond_entete);
  padding: 25px 0 ;
}
.Footer{

}
.FooterMedia{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  align-content: center;
}
.Header {

  background-color: var(--fond_entete) ;
  color: var(--police_entete);
}
.Button {
  padding: 5px 15px;
  text-align: center;
  background-color: var(--fond_boutton) ;
  color: var(--police_boutton);
}
.Menu{
  font-size: 20px;
  padding: 0px 0;
  width: calc(100vw - 100px);
  margin: 0 50px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 30px;
}

.Menu *{
  margin: 15px;
}
.BreadThreads{

  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
}
.BreadThreads path{

  stroke: var(--fond_entete);
  opacity: 0.3;
  stroke-width: 3px;
  stroke-linecap:round;
  stroke-dasharray: 1492;
  /*stroke-dashoffset: 750;*/
}
.BreadThreads path:first-child {
  opacity: 0.4;
  animation-duration: 7s;
  animation-timing-function: linear;
  stroke-dashoffset: 600;
  animation-name: wipe-enter;
}
.BreadThreads path:nth-child(2) {
  opacity: 0.5;
  animation-duration: 7s;
  animation-timing-function: ease-in;
  stroke-dashoffset: 645;
  animation-name: wipe-enter1;
}
.BreadThreads path:nth-child(3) {
  animation-duration: 7s;
  animation-timing-function: ease-out;
  stroke-dashoffset: 702;
  animation-name: wipe-enter2;
}

.BreadThreads path:nth-child(4) {

  opacity: 0.6;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  stroke-dashoffset: 750;
  animation-name: wipe-enter3;
}
@keyframes wipe-enter {
  0% {
    stroke-dashoffset: 1492;
  }
  100% {
    stroke-dashoffset: 600;
  }
}@keyframes wipe-enter1 {
  0% {
    stroke-dashoffset: 1492;
  }
  100% {
    stroke-dashoffset: 645;
  }
}@keyframes wipe-enter2 {
  0% {
    stroke-dashoffset: 1492;
  }
  100% {
    stroke-dashoffset: 702;
  }
}@keyframes wipe-enter3 {
  0% {
    stroke-dashoffset: 1492;
  }
  100% {
    stroke-dashoffset: 750;
  }
}
a {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 1.5px;
  /*font-weight: normal;*/
  font-style: normal;
  text-decoration: none;
  color: var(--police);
  transition:all 0.4s ;
  border-bottom: solid 2px transparent;
}
a.active{
  border-bottom: solid 2px;
  transition:all 0.4s ;
}
a:hover {
  opacity: 0.7;
  transition:all 0.4s ;
}
.Content{
  min-height: calc(100vh - 418px);
}

.Text{

  /*width: 100%;*/
  padding: 15px  50px;
  font-size: 1.5em;
  background-color: #92a99e;
  color: #304a43;
}
.LinkContainer{
  /*width: 100%;*/
  display: flex;
  justify-content: space-around;
  flex-wrap:wrap ;
  max-width: 100vw;

  background-color: #92a99e;
 padding-top: 30px;
  border-bottom: 3px solid #304a43;
}

.LinkContainer a{
  margin: 0 50px 0 50px;
  max-width: 100%;
}
.LinkItem{
  max-width: 100vw;
}
.FullClose {
  position: absolute;
  top : 20px;
  right: 20px;
  font-size: 20px;
}
.Fullscreen {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 15;
  background: rgba(36, 70, 51, 0.65);
}
.Fullscreen img{
  width: 100%;
  height: 100%;
  object-fit: contain;

}
@media (max-width: 1025px) {
  .Menu{
      /*padding: 0 15px ;*/
      font-size: 14px;

  }

  .LinkContainer div{
    /*margin: 0;*/
  }
  .SayingBody {
    text-align: left;
  }
  .Sign{
    text-align: right;
  }
  .Text {
    margin: 0;
    padding:  15px  50px;;
    text-align: justify;
  }
 .Saying{
   font-size: 1.5em;
 }
}