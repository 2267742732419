.Internship {
  text-align: justify;
  padding :0 10% ;
  font-size: 18px;
}
.WorkShopTitle{
  font-size: 22px;
  text-decoration: underline;
}
.WorkShopImageWrapper{
  width: 100%;
  text-align: center;
}
.WorkShopElement{
  padding: 25px;
  margin: 25px 0 ;
  background: #44444422;
}
.InternshipSpecialText{
  display: flex;
  flex: 1 1;
  justify-items: center;
  align-items: center;
}
.InternshipSpecialText a:hover {
   opacity: 1;
   transition:none;
 }

.InternshipSpecialText span{
  flex:1
}
.WorkShopElement img{

  width: 50%;
}

@media (max-width: 1025px) {
  .WorkShopElement img{
    width: 100%;
  }
}
b {
  text-decoration: underline ;
}