.Contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Contact .InputLine{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /*flex-direction: column;*/
  align-items: center;
}
input:focus-visible {
  outline-color:var(--fond_entete);
  outline-width:1px
}
.Contact .InputAndLabel{
  flex: 1;
  margin: 10px 50px;
}
.Contact .InputAndLabel:disabled{
  color:  var( --fond_input);

  background:  var(--police);
  cursor: not-allowed;
}
.Contact form div input{
  padding: 15px;
  border-radius: 5px;
  border: solid 1px var(--fond_input);
}
#contact_message{
  margin: 15px 50px;
  background:  var(--fond_input);
}
input{
  background: var( --fond_input);

  color: var(--police);
}

input[type='submit']:active{
  cursor: pointer;
  border-top: solid 1px var(--fond_entete);
  border-left: solid 1px var(--fond_entete);
  border-bottom: none;
  border-right: none;
}

input[type='submit']:hover{
  cursor: pointer;
    border-bottom: solid 1px var(--fond_entete);
    border-right: solid 1px var(--fond_entete);
}
