

input[type='submit']:hover {
    cursor: pointer;
    border-bottom: solid 1px var(--fond_entete);
    border-right: solid 1px var(--fond_entete);
}

.SayingBG {
    color :#3b3228;
    background-color: #498d6b63;
    /*margin: 15px 0;*/
    /*background-image: url("_2.png");*/
    background-repeat: no-repeat;

    background-size: cover;
    /*border-radius: 15px;*/
}
.Saying {

    font-family: "Dancing Script", cursive;
    z-index: 2;
    display: flex;
    flex-direction: column;

    padding: 50px 50px ;
    backdrop-filter: blur(1px);
    justify-items: left;
    text-align: justify;
    align-items: end;
    font-weight: 600;
    font-style: normal;
    font-size: 1.5em;
}
.SayingBody{
    /*font-family: 'Cracksmoon', sans-serif;*/
    margin: 0 0 30px 0;
    width: 100%;
}

@media (min-width: 1025px) {
    .SayingBG{
        padding: 50px  10%;
    }
    .Saying{
        font-size: 2em;
    }
}